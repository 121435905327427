import { render, staticRenderFns } from "./CompradorLista.vue?vue&type=template&id=538b0ae4&scoped=true&"
import script from "./CompradorLista.vue?vue&type=script&lang=js&"
export * from "./CompradorLista.vue?vue&type=script&lang=js&"
import style0 from "./CompradorLista.vue?vue&type=style&index=0&id=538b0ae4&prod&lang=scss&scoped=true&"
import style1 from "./CompradorLista.vue?vue&type=style&index=1&id=538b0ae4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538b0ae4",
  null
  
)

export default component.exports