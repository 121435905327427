<template>
    <div>
      
      <b-card no-body class="mb-0">
        <div class="m-2">
  
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div>
                <b-button variant="primary" size="md" @click="abrirModalCadastro()">Cadastrar comprador</b-button>
              </div>
            </b-col>
  
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <div>
                  <b-form-group>
                    <b-form-input
                      id="pesquisar"
                      v-model="pesquisar"
                      name="pesquisar"
                      autocomplete="off"
                    />
                  </b-form-group>
                </div>
  
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="usuarios"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Sem resultados"
          :sort-desc.sync="isSortDirDesc"
          :busy="carregamentoApi"
        >
          <!-- Column: User -->
          <template #cell(usuarioId)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'usuarios-comprador-editar', params: { id: data.item.usuarioId } }"
                class="font-weight-bold d-block text-nowrap"
              >#{{ data.item.usuarioId }}</b-link>
            </b-media>
          </template>
  
          <template #cell(statusAprovacao)="data">
            <b-badge
              pill
              :variant="`light-${statusAprovacaoVariant(data.item.statusAprovacao)}`"
              class="text-capitalize"
            >{{ statusAprovacaoLabel(data.item.statusAprovacao) }}</b-badge>
          </template>
  
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
  
              <b-dropdown-item
                :to="{ name: 'usuarios-comprador-editar', params: { id: data.item.usuarioId } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
  
            </b-dropdown>
          </template>
  
          <template #table-busy>
            <div class="text-center">
              <b-spinner small label="Carregando" class="text-primary" />
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            
          </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="totalUsuarios" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="mudarPagina">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
  
      <b-modal id="modal-cadastro" ref="modalCadastro" hide-footer centered no-close-on-backdrop size="lg" title="Cadastro de Comprador">
        
        <validation-observer ref="registerForm" #default="{invalid}">
          <p class="text-center">
            <b-spinner v-if="carregamentoDadosApi" small label="Carregando" class="text-primary" />
          </p>
          <b-form v-if="!carregamentoDadosApi" class="auth-register-form mt-2" @submit.prevent="register">
  
            <b-row>
              <b-col>
                <b-form-group label="Nome Completo" label-for="usuarioNome">
                  <validation-provider #default="{ errors }" name="usuarioNome" vid="usuarioNome" rules="required">
                    <b-form-input id="usuarioNome" v-model="usuarioNome" name="usuarioNome" :state="errors.length > 0 ? false:null" placeholder="Nome e Sobrenome" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col>
                <b-form-group label="Razão Social" label-for="usuarioRazaoSocial">
                  <validation-provider #default="{ errors }" name="usuarioRazaoSocial" vid="usuarioRazaoSocial">
                    <b-form-input id="usuarioRazaoSocial" v-model="usuarioRazaoSocial" name="usuarioRazaoSocial" :state="errors.length > 0 ? false:null" placeholder="Razão Social" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
  
            <b-row>
              <b-col>
                <b-form-group label="CNPJ" label-for="usuarioCnpj">
                  <validation-provider #default="{ errors }" name="usuarioCnpj" vid="usuarioCnpj" rules="cnpj">
                    <the-mask id="usuarioCnpj" v-model="usuarioCnpj" :state="errors.length > 0 ? false:null" :mask="['##.###.###/####-##']" class="form-control" placeholder="00.000.000/0000-00" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col>
                <b-form-group label="CPF" label-for="usuarioCpf">
                  <validation-provider #default="{ errors }" name="usuarioCpf" vid="usuarioCpf" rules="required|cpf">
                    <the-mask id="usuarioCpf" v-model="usuarioCpf" :state="errors.length > 0 ? false:null" :mask="['###.###.###-##']" class="form-control" placeholder="000.000.000-00" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
  
  
            <b-row>
              <b-col>
                <b-form-group label="Data de Nascimento" label-for="usuarioDtNascimento">
                  <validation-provider #default="{ errors }" name="usuarioDtNascimento" vid="usuarioDtNascimento" rules="required">
                    <the-mask id="usuarioDtNascimento" v-model="usuarioDtNascimento" :state="errors.length > 0 ? false:null" :mask="['##/##/####']" :masked="false" class="form-control" placeholder="DD/MM/AAAA" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col>
                <b-form-group label="Whatsapp" label-for="usuarioCelular">
                  <validation-provider #default="{ errors }" name="usuarioCelular" vid="usuarioCelular" rules="required">
                    <the-mask id="usuarioCelular" v-model="usuarioCelular" :state="errors.length > 0 ? false:null" :mask="['(##) #####-####']" class="form-control" placeholder="(00) 00000-0000" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
  
            <b-row>
              <b-col>
                <b-form-group label="Email" label-for="usuarioEmail">
                  <validation-provider #default="{ errors }" name="usuarioEmail" vid="usuarioEmail" rules="required|email">
                    <b-form-input id="usuarioEmail" v-model="usuarioEmail" name="usuarioEmail" :state="errors.length > 0 ? false:null" placeholder="email@exemplo.com" autocomplete="off"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>                
            </b-row>
  
            <b-button variant="primary" class="w-100 mt-2" type="submit" :disabled="invalid || respostaApi">
              Salvar
              <b-spinner v-if="respostaApi" small label="Carregando"/>
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BPagination, BFormFile, BImg,
    BForm, BFormCheckbox
  } from "bootstrap-vue";
  
  import vSelect from "vue-select";
  import VueElementLoading from "vue-element-loading";
  
  import useJwt from "@/auth/jwt/useJwt";
  import { required, email } from '@validations'
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { TheMask } from "vue-the-mask";
  import { cpf, cnpj, cpfOrCnpj } from "vee-cpf-cnpj";
  import DisableAutocomplete from "vue-disable-autocomplete";
  
  // ERROS DOS CAMPOS
  import { localize } from "vee-validate";
  import ComingSoon from "../../miscellaneous/ComingSoon.vue";
  
  extend("cpf", cpf);
  extend("cnpj", cnpj);
  extend("cpf_or_cnpj", cpfOrCnpj);
  
  localize({
    en: {
      messages: {
      },
      fields: {
        usuarioNome: {
          required: 'O nome é obrigatório.',
        },
        usuarioCpf: {
          required: 'O cpf é obrigatório.',
          cpf: 'O cpf é inválido.',
        },
        usuarioCnpj: {
          cnpj: 'O cnpj é inválido.',
        },
        usuarioDtNascimento: {
          required: 'A data é obrigatória.'
        },
        usuarioCelular: {
          required: 'O WhatsApp é obrigatório.',
        },
        usuarioEmail: {
          required: 'O email é obrigatório.',
          email: 'Email inválido.',
        },
      }
    }
  });
  
  import moment from "moment";

  export default {
    components: {
      BSpinner,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BFormInvalidFeedback,
      ValidationProvider,
      ValidationObserver,
      DisableAutocomplete,
      TheMask,
      vSelect,
      VueElementLoading,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
    },
    data() {
      return {
        usuarios: [],
        tableColumns: [
          { key: "usuarioId", label: "ID", sortable: true },
          { key: "nome", label: "Nome", sortable: true },
          { key: "email", label: "Email", sortable: true },
          { key: "cpf", label: "CPF", sortable: true },
          { key: "actions", label: "" }
        ],
        perPage: 25,
        currentPage: 1,
        totalAprovacoes: 0,
        perPageOptions: [10, 25, 50, 100],
        pesquisar: null,
        sortBy: "usuarioId",
        isSortDirDesc: true,
        refUserListTable: null,
        carregamentoApi: false,
        usuarioNome: null,
        usuarioRazaoSocial: null,
        usuarioCnpj: null,
        usuarioDtNascimento: null,
        usuarioCpf: null,
        usuarioCelular: null,
        usuarioEmail: null,
        respostaApi: false,
        carregamentoDadosApi: false,
        totalUsuarios: null,
      };
    },
    created() {
      this.usuariosCompradoresLista();
    },
    watch: {
      pesquisar(newSearchTerm, oldSearchTerm) {
        if (newSearchTerm.length >= 5 || newSearchTerm.length == 0) {
          this.usuariosCompradoresLista()
        }
      }
    },
    methods: {
      formatarData(date) {
        return moment(date, "DDMMYYYY").format("DD/MM/YYYY");
      },
      usuariosCompradoresLista() {
        this.carregamentoApi = true;
        const paginaAtual = this.currentPage - 1;
  
        useJwt
          .usuariosCompradoresLista({
            page: paginaAtual,
            size: this.perPage,
            sort: "ASC",
            pesquisar: this.pesquisar
          })
          .then(response => {
            this.usuarios = response.data.content
            this.totalUsuarios = response.data.totalElements
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.carregamentoApi = false;
          });
      },
  
      mudarPagina() {
        this.usuariosCompradoresLista()
      },
  
      abrirModalCadastro() {
        this.$refs.modalCadastro.show()
      },
  
      register() {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
  
            this.respostaApi = true
  
            useJwt.usuarioCompradorCadastrar({
              nome: this.usuarioNome,
              celular: this.usuarioCelular,
              razaoSocial: this.usuarioRazaoSocial,
              cnpj: this.usuarioCnpj,
              cpf: this.usuarioCpf,
              dataNascimento: this.formatarData(this.usuarioDtNascimento),
              email: this.usuarioEmail,
            })
            .then(response => {
  
                this.$swal({
                title: 'Comprador Cadastrado!',
                text: 'O Comprador foi cadastrado.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
  
                  this.$router.go()
  
                } else {
  
                  this.$router.go()
  
                }
              })
  
              })
              .catch(error => {
                console.log( error.response );
                this.$swal({
                  title: 'Erro!',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .finally(() => {
                this.respostaApi = false
              })
          }
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  